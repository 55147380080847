




























































import { Component, Vue, Ref, Watch } from "vue-property-decorator";
import api from "@/api";
import { BbsPostCommentDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";

@Component({
  components: {
    PagedTableView,
  },
})
export default class BbsPostComment extends Vue {
  editId = 0;
  queryForm = {
    realName: "",
    createTime: undefined,
  };
  postId = 0;

  @Watch("$route.params")
  changeRoute(newVal: any) {
    this.pagedTableView!.fetchData();
  }
  created() {
    if (this.$route.query.postId) {
      this.postId = Number(this.$route.query.postId);
    }
  }

  fetchData(params: any) {
    return api.bbsPostComment.getAll({ ...params, postId: this.postId });
  }

  jumpDetail(index: number, row: BbsPostCommentDto) {
    this.editId = row.id!;
    this.$router.push({
      name: "bbsPostCommentDetail",
      params: { id: row.id!.toString() },
    });
  }

  handleDelete(index: number, row: BbsPostCommentDto) {
    this.$confirm("您确认要评论吗？", "提示").then(() => {
      api.bbsPostComment.delete({ id: row.id }).then(() => {
        this.$message.success("删除成功!");
        this.fetchData(this.queryForm);
      });
    });
  }

  handleOnSaved() {
    this.fetchData(this.queryForm);
  }
}
